// @mui material components
import Icon from "@mui/material/Icon";

// Pages
// import AboutUs from "layouts/pages/landing-pages/about-us";

const routes = [
  {
    name: "About",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
  },
  {
    name: "Events",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
  },
  {
    name: "Services",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Seva to Lord",
        collapse: [
          {
            name: "Panchamruthabhisheka",
          },
          {
            name: "Kunkumarchana",
          },
          {
            name: "Karpurarathi",
          },
        ],
      },
    ],
  },
  {
    name: "Gallery",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
  },
];

export default routes;
